import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearState, fetchData } from "../../../redux/slices/dataSlice";
import server from "../../server";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import CartProductCard from "./Layout/CartProductCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "5px 7px",
  fontSize: "16px",
  fontWeight: "500",
  width: "90%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  mx: "5%",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.data.items);
  const [netMrp, setNetMrp] = useState();
  const [yourSaving, setYourSaving] = useState();
  const [cartValue, setCartValue] = useState();

  const lowStockItem = Object.values(items)?.find(
    (item) => item?.stock - item?.stocklimit < 0,
  );

  //states
  const [cart, setCart] = useState([]);
  const [cartprice, setCartPrice] = useState(
    parseFloat(localStorage.getItem("cartprice")).toFixed(2),
  );
  // const [productDetailsMap, setProductDetailsMap] = useState({});
  const [productDetailsMap, setProductDetailsMap] = useState(items || {});

  const getcartinfo = async () => {
    // setCart(JSON.parse(localStorage.getItem("cart")));
    const localCart = JSON.parse(localStorage.getItem("cart"));
    if (localCart.length > 0) {
      setCart(localCart);
      // await validateCartProducts();
    }
  };

  // const fetchProductDetails = async (cart) => {
  //   const prodData = {};
  //   for (const cartItem of cart) {
  //     const result = await axios.get(
  //       `${server}/public/products/${cartItem[0]}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       },
  //     );
  //     prodData[cartItem[0]] = result.data.product;
  //   }
  //   setProductDetailsMap(prodData);
  // };

  // const validateCartProducts = async () => {
  //   const updatedCart = JSON.parse(localStorage.getItem("cart"));
  //   console.log("updated cart is ", updatedCart);

  //   for (const cartItem of updatedCart) {
  //     try {
  //       const response = await axios.get(
  //         `${server}/public/products/${cartItem[0]}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },
  //         },
  //       );

  //       const product = response.data.product;
  //       console.log("stock is ", product);

  //       // If the product is out of stock, set its quantity to 0
  //       if (product?.stock <= product?.stocklimit) {
  //         toast.info(`${product?.name} is removed because of Out of Stock!`);
  //         cartItem[1] = "0";
  //         const cartprice = localStorage.getItem("cartprice");
  //         const updatedCartPrice = cartprice - product?.price;
  //         localStorage.setItem("cartprice", JSON.parse(updatedCartPrice));
  //       }
  //     } catch (error) {
  //       console.error(`Error fetching product ${productId}:`, error);
  //     }
  //   }

  //   // Save the updated cart to localStorage and state
  //   localStorage.setItem("cart", JSON.stringify(updatedCart));
  //   setCart(updatedCart);
  // };

  const validateCartProducts = async () => {
    const updatedCart = JSON.parse(localStorage.getItem("cart"));
    if (updatedCart.length <= 0) return;

    const filteredCart = await Promise.all(
      updatedCart.map(async (cartItem) => {
        try {
          const response = await axios.get(
            `${server}/public/products/${cartItem[0]}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            },
          );

          const product = response.data.product;

          if (product?.stock <= product?.stocklimit) {
            toast.info(`${product?.name} is removed because of Out of Stock!`);
            // If the product is in stock, calculate net price (product price * quantity)
            // const cartprice = localStorage.getItem("cartprice");
            const netPrice = product.price * cartItem[1]; // Assuming cartItem[1] is the quantity
            const finalPrice = cartprice - netPrice;
            setCartPrice(finalPrice);
            localStorage.setItem("cartprice", JSON.stringify(finalPrice));
            return null; // Mark as null to remove it from the cart
          }

          return cartItem; // Keep the item if it's in stock
        } catch (error) {
          console.error(`Error fetching product ${cartItem[0]}:`, error);
          return cartItem; // In case of error, leave the item in the cart
        }
      }),
    );

    // Filter out any null entries (out-of-stock items)
    const finalCart = filteredCart.filter((item) => item !== null);

    localStorage.setItem("cart", JSON.stringify(finalCart));
    setCart(finalCart);
  };

  // useEffect(() => {
  //   // setTotalSavings(
  //   //   cart.reduce((accumulator, prod) => {
  //   //     // Calculate the savings for the current product
  //   //     console.log(prod);
  //   //     const saved =
  //   //       (prod.length === 3 && prod[2] > 0) ||
  //   //       (prod.length === 2 && parseInt(prod[1], 10) > 0)
  //   //         ? prod.mrpPrice - prod.price
  //   //         : 0;
  //   //     // Accumulate the savings
  //   //     return accumulator + saved;
  //   //   }, 0),
  //   // );
  //   fetchProductDetails(cart);
  // }, [cart]);

  useEffect(() => {
    if (items?.length === 0 || !items || Object.keys(items).length === 0) {
      // console.log("fetch data ", fetchData());
      // dispatch(fetchData());
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch(fetchData())
        .unwrap()
        .then((data) => {
          console.log("Data fetched successfully:", data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      // const data = async () => {
      //   const cart = JSON.parse(localStorage.getItem("cart"));
      //   const productData = await fetchProductDetails(cart);
      //   console.log("product", productData);
      //   dispatch(productData);
      // };
      // data();
    }
  }, [cart]);

  useEffect(() => {
    setProductDetailsMap(items);
  }, [items]);
  const handleFlow = async () => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }

    const addressresult = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // let addressresult;

    // try {
    //   addressresult = await axios.get(
    //     `${server}/address/user/${localStorage.getItem("user")}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //       },
    //     },
    //   );
    // } catch (error) {
    //   console.log(error);
    // }

    // console.log(addressresult.data)

    //check for validity of order minimum rs 500
    if (cartprice < 100) {
      toast.warning("Cannot place orders below ₹100", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (addressresult?.data?.length === 0) {
      //go to add address page with status true
      navigate("/addaddress", { state: { status: "true" } });
    } else {
      navigate("/myaddress", { state: { status: "true" } });
    }
  };

  const getProductById = async (productId) => {
    const product = await axios.get(
      `${server}/public/product/admin/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    return product?.data[0];
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item[0] !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // const calculateNetMRP = async () => {
  //   let netMRP = 0;
  //   const cart = JSON.parse(localStorage.getItem("cart"));
  //   const productPromises = cart.map(async (item) => {
  //     const [productId, quantity] = item;
  //     console.log("productId and quantity", productId, quantity);
  //     const product = await getProductById(productId);
  //     console.log("product", product);
  //     if (product) {
  //       console.log(
  //         "product.mrpPrice and quantity",
  //         product.mrpPrice,
  //         quantity,
  //       );
  //       return product.mrpPrice * quantity;
  //     }
  //     return 0;
  //   });
  //   const productMRPs = await Promise.all(productPromises);
  //   console.log("productMrps", productMRPs);
  //   netMRP = productMRPs.reduce((total, mrp) => total + mrp, 0);
  //   console.log("netMrp", netMrp);
  //   return netMRP;
  // };

  const calculateNetMRP = async () => {
    let netMRP = 0;
    const cart = JSON.parse(localStorage.getItem("cart"));

    const productPromises = cart.map(async (item) => {
      let productId, quantity, variant;

      if (item.length === 2) {
        [productId, quantity] = item; // First is ID, second is quantity
        variant = null; // No variant in this case
      } else if (item.length === 3) {
        [productId, variant, quantity] = item; // First is ID, second is variant, third is quantity
      } else {
        console.error("Unexpected cart item structure", item);
        return 0; // Skip if the structure is not as expected
      }

      const product = await getProductById(productId);
      if (product) {
        if (variant) {
          // Variant exists; calculate accordingly
          let variantFactor;
          if (variant < 100) {
            variantFactor = variant;
          } else {
            variantFactor = parseFloat(variant) / 1000; // Divide variant by 1000
          }

          const totalPrice = product.mrpPrice * quantity * variantFactor;
          return totalPrice;
        } else {
          return product.mrpPrice * quantity;
        }
      }
      return 0;
    });

    const productMRPs = await Promise.all(productPromises);
    netMRP = productMRPs.reduce((total, mrp) => total + mrp, 0);
    return netMRP;
  };

  useEffect(() => {
    // if (!localStorage.getItem("user")) navigate("/");
    // else getcartinfo();
    getcartinfo();
    const validateProduct = async () => {
      await validateCartProducts();
    };

    validateProduct();
  }, []);

  useEffect(() => {
    const fetchAndCalculateMRP = async () => {
      const netMrpValue = await calculateNetMRP();
      const formattedNetMrpValue = netMrpValue.toFixed(2);
      const result = formattedNetMrpValue - cartprice;
      setYourSaving(result.toFixed(2));
      setNetMrp(netMrpValue);
    };

    fetchAndCalculateMRP();
  }, [cartprice]);

  const reset = async () => {
    const cartprice = Number(localStorage.getItem("cartprice"));
    if (Number.isNaN(cartprice)) {
      localStorage.setItem("cartprice", 0);
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch(clearState());
    }
    setCartValue(JSON.parse(localStorage.getItem("cartprice")) <= 0);
  };

  useEffect(() => {
    reset();
    const checkCartPrice = () => {
      const cartPrice = parseFloat(localStorage.getItem("cartprice")) || 0;
      setCartValue(cartPrice <= 0);
    };

    checkCartPrice();
  }, [cartValue, cartprice]);

  return (
    <>
      <ToastContainer />
      {cartValue ? (
        <ShoppingCart />
      ) : (
        <Box sx={{ marginBottom: "100px" }}>
          <Box sx={header}>
            <Box sx={arrowStyle}>
              <Link to="/">
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            </Box>
            <Typography variant="h6" sx={CategoryTitle}>
              Cart
            </Typography>
          </Box>

          {cart &&
            Object.keys(productDetailsMap).length != 0 &&
            cart.map((prod, i) => {
              return (prod.length === 3 && parseInt(prod[2]) > 0) ||
                (prod.length == 2 && parseInt(prod[1]) > 0) ? (
                <CartProductCard
                  proddetail={prod}
                  setCartPrice={setCartPrice}
                  key={i}
                  productDetails={productDetailsMap[prod[0]]}
                  removeFromCart={removeFromCart}
                />
              ) : null;
            })}

          <Box
            sx={{
              position: "fixed",
              bottom: 5,
              right: 2,
              width: "70%",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                bottom: 1,
                left: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "10px 20px",
                color: "green",
                backgroundColor: "white",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                Your Saving
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "green",
                }}
              >
                ₹{yourSaving}
              </Typography>
            </Box>

            <Button
              sx={ButtonCart}
              onClick={(e) => {
                if (!lowStockItem) handleFlow();
                else
                  toast.error(
                    "Out of stock items in your cart, please remove to proceed",
                  );
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                ₹{cartprice}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "inherit",
                }}
              >
                Total
              </Typography>
              <ArrowForwardRoundedIcon
                sx={{
                  position: "absolute",
                  right: "20px",
                }}
              />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Cart;
