import { Button } from "@mui/material";
import { useState } from "react";
import User from "../../../../assets/User.svg";

const Rating = ({ products, fetchLowStockProducts, fetchAllProducts }) => {
  const [isLowStock, setIsLowStock] = useState(true);

  let danger = 0;
  products?.map((item) => {
    // if(item.status=="PUBLISH")
    if (item.stocklimit) {
      if (item.stock < item.stocklimit) danger++;
    }
  });

  const handleToggleClick = () => {
    if (isLowStock) {
      fetchLowStockProducts();
    } else {
      fetchAllProducts();
    }
    setIsLowStock(!isLowStock);
  };

  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={User} className="cart-image" />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body rating-card">
        <div className="abandoned-body-item rating-card">
          <div>
            <p className="abandoned-body-title">Low Stock Alert</p>
            <p className="cart-percentage">{danger}</p>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleClick}
              style={{
                marginTop: "16px",
                backgroundColor: "#1976d2",
                color: "#fff",
                padding: "8px 16px",
                fontSize: "14px",
              }}
            >
              {isLowStock ? "Low Stock" : "All"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
