import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import { weightvariants } from "../../../../assets/variants";
import { NavBarContext } from "../../Navbar/Navbar";

const mainCardDiv = {
  display: "flex",
  borderBottom: "2px solid #F0F0F0",
  marginTop: "10px",
  padding: "10px",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const cardTitle = {
  color: "#37474F",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "14px",
  fontFamily: "Poppins",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "20px",
  fontFamily: "poppins",
};

// const buttonProductCard = {
//   color: "#fff",
//   backgroundColor: "#5EC401",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   padding: "10px 25px",
//   margin: "0px 5px",
//   textTransform: "capitalize",
//   borderRadius: "7px",
//   "&.MuiButtonBase-root:hover": {
//     background: "#64cf00",
//   },
// };

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "50%",
  position: "absolute",
  padding: "5px 12px",
  fontSize: "14px",
  top: "0",
  left: "10px",
};

const buttonPlusMinus = {
  padding: "8px 18px",
  width: "10vw",
  color: "#fff",
  "&.Mui-disabled": {
    // background: "#eaeaea",
    color: "#fff",
  },
};

const CartProductCard = (props) => {
  const items = useContext(NavBarContext);
  const productDetails = props.productDetails ?? [];
  const [isLoading, setIsLoading] = useState(false);
  // const [productDetails, setDetails] = useState([]);
  // const [variants, setVariants] = useState();
  const [quantity, setQuantity] = useState(0);

  // const [defaultweight, setdefaultweight] = useState();

  const [weights, setweights] = useState(weightvariants.weights);
  const [selectedVariant, setSelectedVariant] = useState(0);

  const proddetails = async () => {
    // try {
    //   const result = await axios.get(
    //     `${server}/products/${props.proddetail[0]}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //       },
    //     },
    //   );

    //   setDetails(result.data.product);
    // if (productDetails.length == 0) {
    //   setIsLoading(true);
    // }
    // if (productDetails.length > 0) {
    //   setIsLoading(false);
    // }
    if (props.proddetail.length == 3) {
      setQuantity(parseInt(props.proddetail[2]));
      setSelectedVariant(props.proddetail[1]);
      // setdefaultweight( props.proddetail[1])ss
    } else setQuantity(parseInt(props.proddetail[1]));
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
    // } catch (error) {
    //   // if (error.response.data.message === "Product not found") {
    //   // getVariants()
    //   // }
    //   console.log(error);
    // }
  };

  // const changequantity = (e, i) => {
  //   e.preventDefault();
  //   let cartarr = JSON.parse(localStorage.getItem("cart"));

  //   //july 22 code below

  //   if (i == 1) {
  //     //plus button
  //     // props.proddetail[1] = 6
  //     if (
  //       productDetails.buyinglimit === quantity &&
  //       productDetails.buyinglimit !== 0
  //     ) {
  //       toast.console.error("Buying Limit Exceeded");

  //       return;
  //     }

  //     setQuantity((current) => current + 1);
  //     //change in localstorage also

  //     //check if variant or product FIRST then proceed
  //     //it is a product not a variant
  //     const exists = cartarr.some(function (element) {
  //       if (element[0] == props.proddetail[0]) {
  //         if (element.length === 3) {
  //           element[2] = (parseInt(element[2]) + 1).toString();

  //           if (selectedVariant >= 100) {
  //             // divide by 1000
  //             localStorage.setItem(
  //               "cartprice",
  //               JSON.parse(localStorage.getItem("cartprice")) +
  //                 productDetails.price * (selectedVariant / 1000),
  //             );
  //           } else
  //             localStorage.setItem(
  //               "cartprice",
  //               JSON.parse(localStorage.getItem("cartprice")) +
  //                 productDetails.price * selectedVariant,
  //             );

  //           // props.setCartPrice(localStorage.getItem('cartprice'))
  //           // localStorage.setItem('cartprice', JSON.parse(localStorage.getItem('cartprice')) + parseInt(productDetails.price * selectedVariant))
  //         } else {
  //           element[1] = (parseInt(element[1]) + 1).toString();
  //           localStorage.setItem(
  //             "cartprice",
  //             JSON.parse(localStorage.getItem("cartprice")) +
  //               parseInt(productDetails.price),
  //           );
  //         }

  //         return true;
  //       }
  //     });

  //     // toast.success("Quantity Increased", {
  //     //   position: "top-right",
  //     //   autoClose: 80,
  //     //   hideProgressBar: true,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     // });
  //   } else {
  //     if (quantity == 0) return;
  //     setQuantity((current) => current - 1);

  //     //it is a product not a variant
  //     const exists = cartarr.some(function (element) {
  //       if (element[0] == props.proddetail[0]) {
  //         if (element.length === 3) {
  //           element[2] = (parseInt(element[2]) - 1).toString();

  //           if (selectedVariant >= 100) {
  //             // divide by 1000
  //             localStorage.setItem(
  //               "cartprice",
  //               JSON.parse(localStorage.getItem("cartprice")) -
  //                 productDetails.price * (selectedVariant / 1000),
  //             );
  //           } else
  //             localStorage.setItem(
  //               "cartprice",
  //               JSON.parse(localStorage.getItem("cartprice")) -
  //                 productDetails.price * selectedVariant,
  //             );

  //           if (element[2] == 0) {
  //             //remove from cart
  //             props.removeFromCart(props.proddetail[0]);
  //             items.setItems((current) => current - 1);
  //             return false;
  //           }
  //         } else {
  //           element[1] = (parseInt(element[1]) - 1).toString();
  //           localStorage.setItem(
  //             "cartprice",
  //             JSON.parse(localStorage.getItem("cartprice")) -
  //               productDetails.price,
  //           );
  //           if (element[1] == 0) {
  //             //remove from cart
  //             props.removeFromCart(props.proddetail[0]);
  //             items.setItems((current) => current - 1);
  //             return false;
  //           }
  //         }
  //         return true;
  //       }
  //     });

  //     // toast.success("Quantity Decreased", {
  //     //   position: "top-right",
  //     //   autoClose: 80,
  //     //   hideProgressBar: true,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     // });
  //   }
  //   localStorage.setItem("cart", JSON.stringify(cartarr));

  //   props.setCartPrice(localStorage.getItem("cartprice"));

  //   // if (props.status) props.functionchange(); //inorder to update price
  // };

  const changequantity = (e, i) => {
    e.preventDefault();
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    if (i == 1) {
      // Plus button
      if (
        productDetails.buyinglimit === quantity &&
        productDetails.buyinglimit !== 0
      ) {
        toast.error("Buying Limit Exceeded");
        return;
      }

      setQuantity((current) => current + 1);

      const exists = cartarr.some((element) => {
        if (element[0] == props.proddetail[0]) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();

            const priceIncrease =
              selectedVariant >= 100
                ? productDetails.price * (selectedVariant / 1000)
                : productDetails.price * selectedVariant;

            const updatedCartPrice =
              parseFloat(localStorage.getItem("cartprice")) + priceIncrease;

            localStorage.setItem(
              "cartprice",
              updatedCartPrice.toFixed(2), // Ensure two decimal places
            );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            const updatedCartPrice =
              parseFloat(localStorage.getItem("cartprice")) +
              productDetails.price;

            localStorage.setItem(
              "cartprice",
              updatedCartPrice.toFixed(2), // Ensure two decimal places
            );
          }

          return true;
        }
      });
    } else {
      if (quantity == 0) return;
      setQuantity((current) => current - 1);

      const exists = cartarr.some((element) => {
        if (element[0] == props.proddetail[0]) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) - 1).toString();

            const priceDecrease =
              selectedVariant >= 100
                ? productDetails.price * (selectedVariant / 1000)
                : productDetails.price * selectedVariant;

            const updatedCartPrice =
              parseFloat(localStorage.getItem("cartprice")) - priceDecrease;

            localStorage.setItem(
              "cartprice",
              updatedCartPrice.toFixed(2), // Ensure two decimal places
            );

            if (element[2] == 0) {
              props.removeFromCart(props.proddetail[0]);
              items.setItems((current) => current - 1);
              return false;
            }
          } else {
            element[1] = (parseInt(element[1]) - 1).toString();
            const updatedCartPrice =
              parseFloat(localStorage.getItem("cartprice")) -
              productDetails.price;

            localStorage.setItem(
              "cartprice",
              updatedCartPrice.toFixed(2), // Ensure two decimal places
            );

            if (element[1] == 0) {
              props.removeFromCart(props.proddetail[0]);
              items.setItems((current) => current - 1);
              return false;
            }
          }
          return true;
        }
      });
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));
    props.setCartPrice(localStorage.getItem("cartprice"));
  };

  useEffect(() => {
    proddetails();
  }, [props.productDetails]);

  // useEffect(() => {
  //   // console.log(productDetails)
  // }, [
  //   quantity,
  //   variants,
  //   productDetails,
  //   isLoading,
  //   weights,
  //   // defaultweight,
  //   items.items,
  // ]);

  return (
    <Box>
      <Box sx={mainCardDiv}>
        <ToastContainer />

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={130} height={118}></Skeleton>
          ) : (
            <>
              <Link
                to={`/productdetail/${props?.productDetails?.name}`}
                state={{ prodid: props.proddetail[0] }}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={
                    productDetails.imageUrl === "noimage"
                      ? DefaultImage
                      : productDetails.imageUrl
                  }
                  alt="product-images"
                  style={{
                    width: "130px",
                    maxHeight: "20vh",
                    flex: "25",
                  }}
                  loading="lazy"
                />

                {/* {100 -
									parseInt(
										productDetails.price /
											productDetails.mrpPrice
									) *
										100 >
								0 ? (
									<Typography sx={salePercentage}>
										Save{" "}
										{100 -
											parseInt(
												(productDetails.price /
													productDetails.mrpPrice) *
													100
											)}{" "}
										<br></br>%OFF
									</Typography>
								) : (
									<></>
								)} */}
                {parseInt(productDetails.mrpPrice - productDetails.price) >
                0 ? (
                  <Typography sx={salePercentage}>
                    Save ₹
                    {parseInt(productDetails.mrpPrice - productDetails.price)}{" "}
                    <br></br>
                  </Typography>
                ) : (
                  <></>
                )}
              </Link>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "50",
            padding: "0px 10px",
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "100px" }}
            />
          ) : (
            <Typography sx={cardTitle}>{productDetails?.name}</Typography>
          )}
          {productDetails?.stock - productDetails?.stocklimit < quantity ? (
            <span className="text-white bg-red-500 p-1 rounded-lg w-fit justify-center items-center">
              Low Stock!
            </span>
          ) : null}
          {props.proddetail.length === 3 ? (
            <FormControl
              // size="large"
              sx={{ margin: "10px 0px", width: "100%" }}
            >
              <Select
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 150 } },
                }}
                label="inventory"
                input={<InventoryInput />}
                value={selectedVariant}
                disabled={true}
              >
                {weights &&
                  weights.map((currweight, i) => {
                    return (
                      <MenuItem value={currweight} key={i} name={i}>
                        {currweight >= 100 ? (
                          <>{currweight}gm</>
                        ) : (
                          <>{currweight}kg</>
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {isLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width={60}
                    sx={{ fontSize: "18px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={60}
                    sx={{ fontSize: "18px" }}
                  />
                </>
              ) : productDetails.length !== 0 ? (
                productDetails?.subCategoryId?.name?.includes("Loose") ? (
                  selectedVariant >= 100 ? (
                    <Typography sx={salePriceText}>
                      ₹
                      {(
                        productDetails.price *
                        (selectedVariant / 1000)
                      )?.toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography sx={salePriceText}>
                      ₹{(productDetails.price * selectedVariant)?.toFixed(2)}
                    </Typography>
                  )
                ) : (
                  <>
                    {productDetails.mrpPrice !== productDetails.price && (
                      <Typography sx={priceText}>
                        ₹{productDetails.mrpPrice?.toFixed(2)}
                      </Typography>
                    )}
                    <Typography sx={salePriceText}>
                      ₹{productDetails.price?.toFixed(2)}
                    </Typography>
                  </>
                )
              ) : null}

              {/* <Typography sx={priceText}>₹{productDetails.mrpPrice}</Typography>
    <Typography sx={salePriceText}>₹{productDetails.price}</Typography> */}
            </Box>
            {isLoading ? (
              <Skeleton variant="rectangular" width={80} height={40} />
            ) : props.status === "true" ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F1F1F1",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  Qty: {quantity}
                  {/* <IconButton sx={buttonPlusMinus}>+</IconButton> */}
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F1F1F1",
                    borderRadius: "7px",
                    backgroundColor: "#5EC401",
                    color: "#fff",
                  }}
                >
                  <IconButton
                    sx={buttonPlusMinus}
                    disabled={props.status === "true" ? true : false}
                    onClick={(e) => changequantity(e, 0)}
                  >
                    -
                  </IconButton>
                  {quantity}
                  <IconButton
                    sx={buttonPlusMinus}
                    disabled={
                      props.status === "true" ||
                      productDetails?.stock - productDetails?.stocklimit <
                        quantity
                        ? true
                        : false
                    }
                    onClick={(e) => changequantity(e, 1)}
                  >
                    +
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartProductCard;
