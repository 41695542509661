import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deliveryCharge,
  minValueForFreeDelivery,
} from "../../../constants/constants";
import server from "../../server";
import CartProductCard from "../Cart/Layout/CartProductCard";
import InvalidPincode from "../InvalidPincode/InvalidPincode";
import { NavBarContext } from "../Navbar/Navbar";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
};

const AllProductText = {
  fontWeight: "400",
  fontFamily: "Poppins",
  padding: "8px 20px",
  borderBottom: "1px solid #EAEAEA",
};

const DeliverDiv = {
  padding: "10px 15px",
  borderTop: "8px solid #F2F2F2",
  //   borderBottom: "8px solid #F2F2F2",
};

const DeliverText = {
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: "400",
};

const DeliveryLocation = {
  fontSize: "18px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const ChangeText = {
  fontSize: "20px",
  fontWeight: "500",
  fontFamily: "Poppins",
  color: "#5EC401",
};

const addressText = {
  padding: "0px 0px 0px 20px",
  fontSize: "17px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const NoteText = {
  fontSize: "15px",
  fontWeight: "400",
  fontFamily: "Poppins",
};

const TotalText = {
  fontSize: "19px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const TotalDiv = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "15px 0px",
};

const TotalNote = {
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Poppins",
  color: "#001AFF",
};

const TotalDivTotal = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0px",
  borderBottom: "2px solid #EAEAEA",
  borderTop: "2px solid #EAEAEA",
  padding: "10px 0px",
};

const TotalTotal = {
  fontSize: "19px",
  fontWeight: "600",
  fontFamily: "Poppins",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const PlaceOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navcontext = useContext(NavBarContext);

  const { note, setNote } = navcontext;
  // console.log(note)
  const [cart, setCart] = useState([]);
  const [currentAddress, setAddress] = useState("");
  const [requirements, setRequirements] = useState("");
  const [price, setPrice] = useState();
  const [deliverycharge, setDeliveryCharge] = useState(0);
  const [yourSaving, setYourSaving] = useState();

  const [showModal, setModal] = useState(false);
  const items = navcontext;

  const [loading, setLoading] = useState(false);
  const [totalSavings, setTotalSavings] = useState(0);

  const [productDetailsMap, setProductDetailsMap] = useState({});

  const getcartinfo = async () => {
    setCart(JSON.parse(localStorage.getItem("cart")));
    setPrice(parseFloat(localStorage.getItem("cartprice")));
    // if (localStorage.getItem("cartprice") > 2000) setDeliveryCharge(75);
    // else setDeliveryCharge(0);
  };

  const fetchProductDetails = async (cart) => {
    const prodData = {};
    let savings = 0;
    // console.log(cart);
    for (const cartItem of cart) {
      const result = await axios.get(`${server}/products/${cartItem[0]}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const prodResult = result.data.product;
      prodData[cartItem[0]] = prodResult;
      savings +=
        (prodResult.mrpPrice - prodResult.price) *
        (cartItem?.[2] || cartItem?.[1] || 1);
    }
    setProductDetailsMap(prodData);
    setTotalSavings(savings);
  };

  useEffect(() => {
    // setTotalSavings(
    //   cart.reduce((accumulator, prod) => {
    //     // Calculate the savings for the current product
    //     console.log(prod);
    //     const saved =
    //       (prod.length === 3 && prod[2] > 0) ||
    //       (prod.length === 2 && parseInt(prod[1], 10) > 0)
    //         ? prod.mrpPrice - prod.price
    //         : 0;
    //     // Accumulate the savings
    //     return accumulator + saved;
    //   }, 0),
    // );
    fetchProductDetails(cart);
  }, [cart]);

  const getcurraddress = async () => {
    if (location.state) {
      setAddress({
        addressLine: location.state.name,
        _id: location.state._id,
        pincode: location.state.pincode,
        recipientName: location.state.recipientName,
        recipientPhoneNo: location.state.recipientPhoneNo,
        locationType: location.state.locationType,
      });
      // console.log("using prev");
    } else {
      //this wont be used mostly
      const result = await axios.get(
        `${server}/address/user/${localStorage.getItem("user")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log("fetching frm db");
      if (result.data.length > 0) setAddress(result.data[0]);
      // else setAddress()
    }
  };

  const placeorder = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(currentAddress);
    //check for validity of address
    const checkaddress = await axios.get(
      `${server}/pincodevalidate/${currentAddress.pincode.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(checkaddress)
    if (checkaddress.data === null) {
      setModal(true);
      return;
    }

    let cartarr = JSON.parse(localStorage.getItem("cart"));
    let productList = [];
    cartarr.forEach((element) => {
      if (element.length == 3) {
        if (parseInt(element[2]) > 0) {
          // console.log('prod added with id  ' + element[0])
          productList.push({
            productId: element[0],
            itemCount: element[2],
            variant: element[1],
          });
        }
      } else {
        if (parseInt(element[1]) > 0) {
          // console.log('prod added with id  ' + element[0])
          productList.push({
            productId: element[0],
            itemCount: element[1],
          });
        }
      }
    });

    // console.log(productList)

    let result;

    try {
      const response = await axios.post(
        `${server}/orders`,
        {
          userId: localStorage.getItem("user"),
          productList: productList,
          deliveryAddressId: currentAddress._id,
          message: requirements,
          totalAmount: parseFloat(price) + parseFloat(deliverycharge),
          recipientName: currentAddress.recipientName,
          recipientPhoneNo: currentAddress.recipientPhoneNo,
          locationType: currentAddress.locationType,
          addressLine: currentAddress.addressLine,
          pincode: currentAddress.pincode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      result = response.data;
    } catch (error) {
      toast.error("Server seems to be down try again later", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    // console.log(result)
    setNote("");
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("cartprice", 0);

    // //send notification
    const notif = await axios.post(
      `${server}/notifications/send`,
      {
        userId: localStorage.getItem("user"),
        orderId: result._id,
        message: "Order has been placed ",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    items.setItems(0);

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    await sleep(3000);
    setLoading(false);

    navigate(`/orderplaced/1/${result._id}`, { replace: true });
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else {
      getcartinfo();
      getcurraddress();
    }
  }, []);

  useEffect(() => {
    // console.log(currentAddress)
    if (localStorage.getItem("cartprice") < minValueForFreeDelivery)
      setDeliveryCharge(deliveryCharge);
  }, [price]);

  const changepricefromchild = () => {
    setPrice(parseFloat(localStorage.getItem("cartprice")));
  };

  const getProductById = async (productId) => {
    const product = await axios.get(
      `${server}/public/product/admin/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    return product?.data[0];
  };

  const calculateNetMRP = async () => {
    let netMRP = 0;
    const cart = JSON.parse(localStorage.getItem("cart"));

    const productPromises = cart.map(async (item) => {
      let productId, quantity, variant;
      if (item.length === 2) {
        [productId, quantity] = item; // First is ID, second is quantity
        variant = null; // No variant in this case
      } else if (item.length === 3) {
        [productId, variant, quantity] = item; // First is ID, second is variant, third is quantity
      } else {
        console.error("Unexpected cart item structure", item);
        return 0; // Skip if the structure is not as expected
      }
      const product = await getProductById(productId);
      if (product) {
        if (variant) {
          // Variant exists; calculate accordingly
          let variantFactor;
          if (variant < 100) {
            variantFactor = variant;
          } else {
            variantFactor = parseFloat(variant) / 1000; // Divide variant by 1000
          }

          const totalPrice = product.mrpPrice * quantity * variantFactor;
          return totalPrice;
        } else {
          return product.mrpPrice * quantity;
        }
      }
      return 0;
    });

    const productMRPs = await Promise.all(productPromises);
    netMRP = productMRPs.reduce((total, mrp) => total + mrp, 0);

    return netMRP;
  };

  useEffect(() => {
    const fetchAndCalculateMRP = async () => {
      const netMrpValue = await calculateNetMRP();
      const formattedNetMrpValue = netMrpValue.toFixed(2);
      const result = formattedNetMrpValue - price;
      setYourSaving(result.toFixed(2));
    };

    fetchAndCalculateMRP();
  }, [price]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      {showModal && <InvalidPincode modalstate={setModal} />}
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/cart">
            <IconButton>
              <ArrowBackRoundedIcon />
            </IconButton>
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Checkout
        </Typography>
      </Box>
      <Typography variant="h6" sx={AllProductText}>
        All Products
      </Typography>
      {cart &&
        cart.map((prod, index) => {
          return (prod.length === 3 && prod[2] > 0) ||
            (prod.length == 2 && parseInt(prod[1]) > 0) ? (
            <CartProductCard
              proddetail={prod}
              setCartPrice={setPrice}
              functionchange={changepricefromchild}
              status="true"
              key={index}
              productDetails={productDetailsMap[prod[0]]}
            />
          ) : (
            <></>
          );
        })}

      <Box sx={DeliverDiv}>
        <Typography sx={DeliverText}>
          Get it delivered to your doorstep in 48-72 hours
        </Typography>
      </Box>
      <Box sx={DeliverDiv}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <LocationOnOutlinedIcon />
            <Typography sx={DeliveryLocation}>Delivery Location</Typography>
          </div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate("/myaddress", {
                state: { status: "true" },
              });
            }}
            style={{ textDecoration: "none" }}
          >
            <Typography sx={ChangeText}>Change</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          {/* style={{
              color: "#37474F",
              backgroundColor: "rgba(35, 108, 217, 0.121039)",
              padding: "10px",
              borderRadius: "50%",
            }}
          /> */}
          <Typography sx={addressText}>{currentAddress.addressLine}</Typography>
        </Box>
      </Box>
      <Box sx={DeliverDiv}>
        <Typography sx={NoteText}>
          Write a note if you have any specific requirement!
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
            position: "relative",
          }}
        >
          <textarea
            className="note-textarea"
            placeholder="Any Specific Requirements.."
            value={requirements}
            onChange={(e) => setRequirements(e.target.value)}
          />
        </Box>
      </Box>
      <Box sx={DeliverDiv}>
        <Typography sx={DeliverText}>
          Note: For any returns please request within 7 days
        </Typography>
      </Box>
      <Box sx={DeliverDiv}>
        <Box sx={TotalDiv}>
          <Typography sx={TotalText}>Subtotal</Typography>
          <Typography sx={TotalText}>₹{(price || 0.0).toFixed(2)}</Typography>
        </Box>
        <Box sx={{ ...TotalDiv, color: "green" }}>
          <Typography sx={TotalText}>Savings</Typography>
          <Typography sx={TotalText}>₹{yourSaving}</Typography>
        </Box>
        <Box sx={TotalDiv}>
          <Typography sx={TotalText}>Delivery Charge</Typography>
          <Typography sx={TotalText}>₹{deliverycharge}</Typography>
        </Box>
        {price < minValueForFreeDelivery && (
          <Typography sx={TotalNote}>
            {`Note: Add Items worth ₹${
              minValueForFreeDelivery - price
            } more to get FREE Delivery.`}
          </Typography>
        )}
        <Box sx={TotalDivTotal}>
          <Typography sx={TotalTotal}>Total</Typography>
          <Typography sx={TotalTotal}>
            ₹{(parseFloat(price) + parseFloat(deliverycharge)).toFixed(2)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ padding: "0px 10px", position: "sticky", bottom: 90 }}>
        {/* <Link to="/orderplaced"> */}
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            // try {
            placeorder(e);
            // } catch (err) {}
          }}
          disabled={loading}
        >
          {loading ? "Processing..." : "Place Order"}
          <ArrowForwardRoundedIcon
            sx={{ position: "absolute", right: "20px" }}
          />
        </Button>
        {/* </Link> */}
      </Box>
    </Box>
  );
};

export default PlaceOrder;
