import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deliveryCharge,
  deliveryThreshold,
} from "../../../constants/constants";
import server from "../../server";
import ViewOrderCard from "./Layout/ViewOrderCard";
const header = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
  display: "flex",
  flexWrap: "wrap",
  fontSize: "16px",
};

const AllProductText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  padding: "8px 20px",
  borderBottom: "1px solid #EAEAEA",
};

const DeliverDiv = {
  padding: "10px 15px",
  borderTop: "8px solid #F2F2F2",
};

const DeliverText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const DeliveryLocation = {
  fontSize: "18px",
  fontWeight: "400",
  fontFamily: "Poppins",
};

const ChangeText = {
  fontSize: "20px",
  fontWeight: "700",
  fontFamily: "Poppins",
  color: "#5EC401",
};

const addressText = {
  padding: "0px 0px 0px 20px",
  fontSize: "17px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const NoteText = {
  fontSize: "15px",
  fontWeight: "600",
  fontFamily: "Poppins",
};

const TotalDivTotal = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
};

const TotalTotal = {
  fontSize: "19px",
  fontWeight: "600",
  fontFamily: "Poppins",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "400",
  width: "100%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const ViewOrder = ({ pageType }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [orderinfo, setOrderInfo] = useState({});
  const [customorderid, setid] = useState();
  const [address, setAddress] = useState();

  const [RWseries, setSeries] = useState();

  const productList = orderinfo?.productList;

  const getOrderDetails = async () => {
    const seriesnumber = await axios.get(`${server}/admin/orders/get/series`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setSeries(seriesnumber.data.series);

    const result = await axios.get(`${server}/orders/id/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setOrderInfo(result.data);
    setid(result.data.orderId);
    //adress contains entire order for now
    setAddress(result.data);
  };

  const getAddress = async (addressId) => {
    try {
      const result = await axios.get(`${server}/address/find/${addressId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setAddress(result.data);
      // console.log(addressId)
      // console.log(result.data)
    } catch (error) {
      console.log(error);
    }
  };

  const changeorder = async () =>
    await axios.put(
      `${server}/orders/update/${orderId}`,
      {
        status: "cancelled",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getOrderDetails();
  }, []);

  // useEffect(() => {
  // }, [orderinfo, address])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        marginBottom: "100px",
      }}
    >
      <Box sx={header}>
        <Box sx={arrowStyle}>
          {/* <Link to="/client/orders"> */}
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              navigate("/client/orders", {
                state: { value: location?.state?.value },
              });
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {/* </Link> */}
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Order #{String(RWseries) + String(customorderid).padStart(6, "0")}
        </Typography>
      </Box>
      {pageType == "cancelOrder" && (
        <Typography variant="h6" sx={DeliverDiv}>
          Cancel Order
        </Typography>
      )}

      {productList &&
        productList.map((currproduct, i) => {
          return (
            <ViewOrderCard
              productId={currproduct.productId}
              quantity={currproduct.itemCount}
              price={currproduct.price}
              variant={currproduct.variant}
              key={i}
              product={currproduct}
            />
          );
        })}

      {pageType == "cancelOrder" ? (
        <>
          <Box
            sx={{
              padding: "0px 10px",
              position: "sticky",
              bottom: "90px",
              background: "white",
            }}
          >
            {/* <Link to={`/trackorder/${orderId}`}> */}
            <Typography
              sx={{
                ...addressText,
                mb: 1,
                padding: 0,
                textAlign: "center",
              }}
            >
              Once order is cancelled kindly reorder any necessary items again.
            </Typography>
            <Button
              sx={ButtonCart}
              // onClick={(e) => {
              //   e.preventDefault();
              //   navigate(`/trackorder/${orderId}`, {
              //     state: { value: location?.state?.value },
              //   });
              // }}
              onClick={() => {
                changeorder().then(
                  navigate(`/trackorder/${orderId}`, {
                    state: { value: location?.state?.value },
                    replace: true,
                  }),
                );

                //   navigate(`/trackorder/${orderId}`, {
                //     state: { value: location?.state?.value },
                //   });
              }}
            >
              Confirm
              {/* <ArrowForwardRoundedIcon
                  sx={{ position: "absolute", right: "20px" }}
                /> */}
            </Button>
            {/* </Link> */}
          </Box>
        </>
      ) : (
        <>
          <Box sx={DeliverDiv}>
            <div className="text-xl mb-4">
              <p className="flex justify-between">
                <span className="font-bold mr-2">Delivery Charge:</span>₹
                {orderinfo?.totalAmount > deliveryThreshold
                  ? deliveryCharge
                  : 0}
              </p>
              <p className="flex justify-between">
                <span className="font-bold mr-2">Total:</span>₹
                {orderinfo?.totalAmount +
                  (orderinfo?.totalAmount > deliveryThreshold
                    ? deliveryCharge
                    : 0)}
              </p>
            </div>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={DeliveryLocation}>Delivery Location</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 0px",
              }}
            >
              <LocationOnOutlinedIcon
                style={{
                  color: "#37474F",
                  backgroundColor: "rgba(35, 108, 217, 0.121039)",
                  padding: "10px",
                  borderRadius: "50%",
                }}
              />

              <Typography sx={addressText}>
                {address ? <> {address.addressLine}</> : <></>}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ padding: "0px 10px" }}>
            <Link to={`/trackorder/${orderId}`}>
              <Button
                sx={ButtonCart}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/trackorder/${orderId}`, {
                    state: { value: location?.state?.value },
                  });
                }}
              >
                Track Order
                <ArrowForwardRoundedIcon
                  sx={{ position: "absolute", right: "20px" }}
                />
              </Button>
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ViewOrder;
