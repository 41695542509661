import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import ExcelIcon from "../../../assets/excelicon.png";
import Plus from "../../../assets/plus.svg";
import UploadCloud from "../../../assets/upload-cloud.svg";
import UploadImage from "../../../assets/upload-image.svg";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import Allproducts from "../Dashboard/Layout/Allproducts";
import InventoryTable from "./Layout/InventoryTable";
import Rating from "./Layout/Rating";

import CircularProgressWithLabel from "./Layout/CircularProgressWithLabel";

import { toast } from "react-toastify";
import io from "socket.io-client";
import useStore from "../stores.ts";

// same origin version

const topRightToastStyles = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

// let socket;

// if (localStorage.getItem("adminuser")) {
const socket = io(`${server}/admin/sync`, {
  auth: { token: localStorage.getItem("accessToken") },
  closeOnBeforeunload: true,
  autoConnect: false,
  reconnectionDelay: 300,
});
socket.on("connect", () => {
  console.log("Connected to socket!");
});
socket.on("disconnect", () => {
  console.log("Disconnected to socket!");
});
// }
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const Inventory = () => {
  const { products, setProducts } = useStore();

  const [jsonproducts, setJson] = useState();
  const [filename, setfilename] = useState();
  const context = useContext(DashboardContext);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const { loading, setLoading } = context;
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [labelChecked, setLabelChecked] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setJson([]);
    setfilename();
    if (!loading) dataProducts();
  };

  const dataProducts = async (initial = false) => {
    console.log("dataProducts");
    if ((initial && products?.length) || loading) {
      return;
    }
    setLoading(true);
    // console.log("inside fetch")
    const result = await axios.get(`${server}/admin/products`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log("alll products before ", result.data);
    setProducts(result.data);
    // console.log(result.data[0]);
    await delay(1000);
    setLoading(false);
  };

  const fetchLabelCodeData = async () => {
    const result = await axios.get(
      `${server}/admin/products-sortedbylabelcode`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // setProductsByLabelCode(result.data);
    setProducts(result.data);
  };

  const UploadImagesInDB = async () => {
    if (jsonproducts.length === 0) {
      alert("please add products");
      return;
    }

    const result = await axios.put(
      `${server}/admin/updaterwproducts`,
      {
        data: jsonproducts,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
    //also call handleclose next and ser json back to empty
    setJson([]);
    handleClose();
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        // console.log(json);
        setJson(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const callSyncApi = async () => {
    socket.emit("sync-products");
    setSyncProgress({ progress: 5, msg: "Started sycing products.." });
    return;
    // try {
    //   setLoading(true);
    //   const result = await axios.get(`${server}/admin/sync-with-retailware`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
    //     },
    //   });
    //   console.log(result);
    // } catch (error) {
    //   console.log(error.message);
    // }
    // setLoading(false);
  };

  const getSyncStaus = () => {
    axios
      .get(`${server}/admin/product_sync_status`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        setSyncProgress(res.data);
      })
      .catch((err) => console.log(err));
  };

  const [syncing, setSyncing] = useState(false);
  const [syncProgress, setSyncProgress] = useState({ progress: 0, msg: "" });

  const exportToExcel = () => {
    if (products?.length > 0) {
      // Create a new array with transformed data
      const transformedProducts = products?.map((product) => ({
        RWID: product?.rwId,
        ProductName: product?.name,
        Category: product?.categoryId?.name,
        Subcategory: product?.subCategoryId?.name,
        mrpPrice: product?.mrpPrice,
        Stock: product?.stock,
        Stocklimit: product?.stocklimit,
        labelcode: product?.labelcode,
        Barcode: product?.barcode?.join(", ") || "",
        published: product?.published,
        price: product?.price,
      }));

      // Create a new workbook
      const workbook = xlsx.utils.book_new();

      // Convert the product data into a worksheet
      const worksheet = xlsx.utils.json_to_sheet(transformedProducts);

      // Append the worksheet to the workbook
      xlsx.utils.book_append_sheet(workbook, worksheet, "Products");

      // Export the workbook to an Excel file
      xlsx.writeFile(workbook, "Product_Inventory.xlsx");
    }
  };

  const getLowstockProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}/admin/lowstockproducts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setProducts(response?.data);
      setLoading(false);
      console.log("low stock product ", response.data);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    setLoaded(true);

    if (!loaded) {
      getSyncStaus();
      dataProducts(true);
      // fetchLabelCodeData();

      socket.connect();
      socket.on("sync-products-update", (body) => setSyncProgress(body));
    }
    // return () => {
    //   socket.disconnect();
    // };
  }, [loaded]);

  useEffect(() => {
    if (
      syncProgress?.progress == -1 ||
      syncProgress?.msg?.startsWith("Error")
    ) {
      toast.error(
        "Error syncing products RetailWare API may be down!",
        topRightToastStyles,
      ) && console.log(syncProgress);
      setTimeout(() => {
        setSyncing(false);
      }, 5000);
      if (!loading) dataProducts();
    } else if (syncProgress?.progress == 100) {
      toast.success("Products synced!", topRightToastStyles);
      // && socket.disconnect();
      setTimeout(() => {
        setSyncing(false);
      }, 5000);
      if (!loading) dataProducts();
    } else if (syncProgress?.progress > 0) {
      !syncing && setSyncing(true);
    }
  }, [syncProgress]);

  // useEffect(() => {
  //   if (labelChecked) {
  //     fetchLabelCodeData();
  //   } else {
  //     if (!loading) dataProducts();
  //   }
  // }, [labelChecked]);

  // console.log({ labelChecked });

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {" "}
              Upload Excel
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="imageUpload-div">
              <div>
                <div className="upload-main-div">
                  <input
                    type="file"
                    id={`image-upload`}
                    // accept="image/*"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setfilename(event.target.files[0].name);
                      readUploadFile(event);
                    }}
                  />
                  {filename ? (
                    <label htmlFor="image-upload">
                      <div>
                        <img
                          alt="upload-img"
                          src={ExcelIcon}
                          className="upload-image"
                        />
                        <div className="upload-cloud-div">
                          <img
                            alt="upload-img"
                            src={UploadCloud}
                            className="upload-cloud"
                          />
                          <p className="upload-text">
                            Succesfully Uploaded Excel
                          </p>
                        </div>
                        <p className="upload-desc">
                          File uploaded - {filename}
                        </p>
                      </div>
                    </label>
                  ) : (
                    <label htmlFor="image-upload">
                      <div>
                        <img
                          alt="upload-img"
                          src={UploadImage}
                          className="upload-image"
                        />
                        <div className="upload-cloud-div">
                          <img
                            alt="upload-img"
                            src={UploadCloud}
                            className="upload-cloud"
                          />
                          <p className="upload-text">Upload Excel</p>
                        </div>
                        <p className="upload-desc">
                          Upload excel for your products.
                        </p>
                        <p className="upload-desc">
                          File Format <span>xlsx</span>
                        </p>
                      </div>
                    </label>
                  )}
                </div>
              </div>
            </div>

            <Button
              onClick={(e) => {
                e.preventDefault();
                UploadImagesInDB();
              }}
              sx={{
                backgroundColor: "#5570F1",
                width: "fit-content",
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Submit File
            </Button>
          </Box>
        </Box>
      </Modal>

      <div className="order-head">
        <p className="order-title">Inventory Summary</p>
        {/* <Link
          to="/addproduct"
          state={{id:-1,isEdit: false }}
          style={{ textDecoration: "none" }}
        > */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 24,
          }}
        >
          <p
            className="order-title"
            style={{ display: "flex", alignItems: "center" }}
          >
            {syncProgress?.msg}
          </p>
          {syncing && (
            <>
              <CircularProgressWithLabel value={syncProgress?.progress ?? 0} />
            </>
          )}
          <button
            onClick={(e) => {
              e.preventDefault();
              callSyncApi();
            }}
          >
            {/* <img alt="plus" src={Plus} /> */}
            Sync Products
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleOpen();
            }}
          >
            <img alt="plus" src={Plus} />
            Upload Excel
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              exportToExcel();
            }}
          >
            Download Excel
          </button>
        </div>
        {/* </Link> */}
      </div>
      <div className="inventory-grid">
        <div className="allproducts">
          <Allproducts products={products} />
        </div>
        <div className="rating">
          <Rating
            products={products}
            fetchAllProducts={dataProducts}
            fetchLowStockProducts={getLowstockProducts}
          />
        </div>
      </div>
      <div>
        <InventoryTable
          products={
            !labelChecked
              ? products
              : products?.sort((a, b) => b?.labelcode - a?.labelcode) || []
          }
          fetchproducts={dataProducts}
          setName={setName}
          setCategory={setCategory}
          setSubcategory={setSubcategory}
          labelChecked={labelChecked}
          setLabelChecked={setLabelChecked}
        />
      </div>
    </div>
  );
};

export default Inventory;
