import { create } from "zustand";

// Define the Zustand store
const useStore = create((set) => ({
  // State: Array of products
  products: [],
  productsPageNo: 0,
  orders: [],

  // Actions: Set the products array
  setProducts: (newProducts) => set({ products: newProducts }),

  // Action: Update a product by its id
  updateProduct: (updatedProduct) => {
    set((state) => {
      const index = state.products.findIndex(
        (product) => product._id === updatedProduct._id,
      );

      if (index !== -1) {
        // If product found, update it
        const updatedProducts = [...state.products];
        updatedProducts[index] = {
          ...updatedProducts[index],
          ...updatedProduct,
          categoryId: updatedProducts[index].categoryId,
          subCategoryId: updatedProducts[index].subCategoryId,
          labelcode: updatedProducts[index].labelcode,
          stocklimit: updatedProducts[index].stocklimit,
        }; // Update product at found index

        console.log({ op: updatedProducts[index] });

        return { products: updatedProducts };
      } else {
        console.error("Product not found for update.");
        return state; // No change if product not found
      }
    });
  },

  // Action: Add a new product
  addProduct: (newProduct) =>
    set((state) => ({
      products: [...state.products, newProduct], // Append the new product to the existing array
    })),

  setOrders: (newOrders) => set({ orders: newOrders }),

  // Action: Update an order by its id
  updateOrder: (updatedOrder) =>
    set((state) => ({
      orders: state.orders.map((order) =>
        order._id === updatedOrder._id ? updatedOrder : order,
      ),
    })),

  setProductsPageNo: (productsPageNo) => set({ productsPageNo }),
}));

export default useStore;
