import axios from "axios";
import { useEffect, useState } from "react";
import server from "../../../server";

const BarcodeTable = ({ fetchedBarcodes = [] }) => {
  // State to hold barcode data and the set of modified barcode IDs
  const [barcodes, setBarcodes] = useState(fetchedBarcodes || []);
  const [modifiedBarcodes, setModifiedBarcodes] = useState(new Set());

  useEffect(() => {
    setBarcodes(fetchedBarcodes);
  }, [fetchedBarcodes]);

  // Handle changes to individual input fields
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedBarcodes = [...barcodes];
    updatedBarcodes[index] = { ...updatedBarcodes[index], [name]: value };
    setBarcodes(updatedBarcodes);

    // Track modified barcode IDs
    setModifiedBarcodes((prev) => new Set(prev).add(barcodes[index]._id));
  };

  // Submit changes for only modified barcodes
  const handleSubmit = async () => {
    if (modifiedBarcodes.size === 0) {
      alert("No changes made");
      return;
    }

    try {
      const requests = barcodes
        .filter((barcode) => modifiedBarcodes.has(barcode._id)) // Only modified barcodes
        .map((barcode) =>
          axios.patch(`${server}/barcodes/${barcode._id}/`, barcode, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Include the token
            },
          }),
        );

      // Wait for all requests to finish
      await Promise.all(requests);
      alert("Barcodes updated successfully!");
      setModifiedBarcodes(new Set()); // Reset modified barcodes
    } catch (error) {
      console.error("Error updating barcodes:", error);
      alert("Error updating barcodes");
    }
  };

  return (
    <div className="max-w-[900px] overflow-x-scroll">
      {barcodes.length > 0 ? (
        <>
          <div className="flex justify-between items-center my-4">
            <h2 className="text-xl font-semibold">Barcode Table</h2>
            {modifiedBarcodes.size === 0 ? null : (
              <button
                onClick={handleSubmit}
                disabled={modifiedBarcodes.size === 0}
                style={{
                  backgroundColor: "#1890ff",
                  color: "white",
                  padding: "8px",
                  //   marginTop: "16px",
                  marginLeft: "auto",
                }}
              >
                Submit Changes
              </button>
            )}
          </div>
          <table style={{ width: "100%", tableLayout: "auto" }}>
            <thead>
              <tr>
                <th
                  style={{
                    maxWidth: "120px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  RW Code
                </th>
                <th
                  style={{
                    minWidth: "130px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  EAN Code
                </th>
                <th
                  style={{
                    maxWidth: "120px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  Batch
                </th>
                <th
                  style={{
                    maxWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    maxWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  MRP
                </th>
                <th
                  style={{
                    maxWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  Stock
                </th>
                <th
                  style={{
                    minWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  Expiry
                </th>
                <th
                  style={{
                    // maxWidth: "200px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  Manufactured
                </th>
              </tr>
            </thead>
            <tbody>
              {barcodes
                .sort(
                  (a, b) =>
                    new Date(b.manufactured).getTime() -
                    new Date(a.manufactured).getTime(),
                )
                .map((barcode, index) => (
                  <tr key={barcode._id}>
                    {[
                      "code",
                      "ean_code",
                      "batch",
                      "rate",
                      "mrp",
                      "stock",
                      "expiry",
                      "manufactured",
                    ].map((field) => (
                      <td
                        key={field}
                        style={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          overflow: "scroll",
                        }}
                      >
                        <input
                          type={
                            field.includes("rate") ||
                            field.includes("mrp") ||
                            field.includes("stock")
                              ? "number"
                              : "text"
                          }
                          name={field}
                          value={barcode[field] || ""}
                          onChange={(e) => handleInputChange(index, e)}
                          style={{ width: "100%" }} // Ensure the input spans the full width of the table cell
                          disabled
                        />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
};

export default BarcodeTable;
